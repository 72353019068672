import {
  AppBar,
  Button,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import { useContext, useState } from 'react';
import { UserContext } from '../../hooks/userContext/userContext';

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);

  const { user } = useContext(UserContext);
  // console.log(user);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          href={'/'}
          size="medium"
          edge="start"
          color="inherit"
          aria-label="home Landing Page"
        >
          <HomeIcon />
        </IconButton>
        <Box component="div" sx={{ flexGrow: 1 }}>
          <Button href={'/topics-editor'} color="inherit" style={{ textTransform: 'none' }}>
            <Typography variant="h6">Portal Topics Editor</Typography>
          </Button>
        </Box>
        <Button
          color="inherit"
          id="resources-button"
          onClick={handleClick}
          aria-controls={open ? 'resources-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          endIcon={<MenuIcon />}
        >
          {user.auth ? (
            <Typography>{user.data.username.toUpperCase()}</Typography>
          ) : (
            <Typography>MENU</Typography>
          )}
        </Button>
        <Menu
          id="resources-menu"
          anchorEl={anchorEl}
          open={open}
          MenuListProps={{ 'aria-labelledby': 'resources-button' }}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {user.auth ? (
            <MenuItem component="a" href={'/profile'}>
              Profile
            </MenuItem>
          ) : null}
          {user.data.can_manage_users === '1' ? (
            <MenuItem component="a" href={'/user-management'}>
              User Management
            </MenuItem>
          ) : null}
          <MenuItem component="a" href={'/topics-editor'}>
            Tool
          </MenuItem>
          <MenuItem component="a" href={'/contact-us'}>
            Contact Us
          </MenuItem>

          {user.auth ? (
            <MenuItem component="a" href={'/logout'}>
              Logout
            </MenuItem>
          ) : (
            <MenuItem component="a" href={'/login'}>
              Login
            </MenuItem>
          )}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
