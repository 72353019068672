import React, { useCallback, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';

import { Delete, Edit } from '@mui/icons-material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';

import axios from 'axios';
import FormData from 'form-data';
import MuiSnackBar from '../../../components/MaterialComponents/MuiSnackBar';
import DeleteDialog from './DeleteDialog';
import { ExportToCsv } from 'export-to-csv';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const UsersTable = (props) => {
  const [originalRow, setOriginalRow] = useState({
    user_id: null,
    username: null,
  });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  //snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState({
    status: null,
    message: null,
    error: false,
  });

  //for Editing
  const getEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid =
            cell.column.id === 'email'
              ? validateEmail(event.target.value)
              : cell.column.id === 'age'
              ? validateAge(+event.target.value)
              : validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors],
  );

  //table columns
  // const columnsMemo = useMemo(() => columns, [getEditTextFieldProps]);
  const columnsMemo = useMemo(
    () => [
      {
        header: 'user_id',
        size: '100',
        accessorKey: 'user_id',
        enableEditing: false,
        type: 'number',
      },
      {
        header: 'Name',
        size: '120',
        accessorKey: 'username',
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getEditTextFieldProps(cell),
          type: 'string',
        }),
      },
      {
        header: 'Email',
        accessorKey: 'email',
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getEditTextFieldProps(cell),
          type: 'string',
        }),
      },
      {
        header: 'Active',
        accessorKey: 'active',
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getEditTextFieldProps(cell),
          type: 'number',
        }),
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue() === '1' ? (
              <CheckIcon color="success" value="1" />
            ) : (
              <CloseIcon color="error" value="0" />
            )}
          </Box>
        ),
      },
      {
        header: 'Admin',
        accessorKey: 'can_manage_users',
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getEditTextFieldProps(cell),
          type: 'number',
        }),
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue() === '1' ? (
              <CheckIcon color="success" value="1" />
            ) : (
              <CloseIcon color="error" value="0" />
            )}
          </Box>
        ),
      },
      {
        header: 'Portal Editor',
        accessorKey: 'portal_editor_tool_access',
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getEditTextFieldProps(cell),
          type: 'number',
        }),
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue() === '1' ? (
              <CheckIcon color="success" value="1" />
            ) : (
              <CloseIcon color="error" value="0" />
            )}
          </Box>
        ),
      },
      {
        header: 'Taxonomy Tool',
        accessorKey: 'taxonomy_tool_access',
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getEditTextFieldProps(cell),
          type: 'number',
        }),
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue() === '1' ? (
              <CheckIcon color="success" value="1" />
            ) : (
              <CloseIcon color="error" value="0" />
            )}
          </Box>
        ),
      },
    ],
    [getEditTextFieldProps],
  );

  //   console.log(props.isLoading);
  //   console.log(props.usersObject);
  //for Delete row
  const deleteRowDialogHandle = (row) => {
    setOriginalRow(row.original);
    setDeleteDialogOpen(true);
  };
  const deleteRowHandle = () => {
    setDeleteDialogOpen(false);
    console.log(originalRow);
    setOriginalRow({
      user_id: null,
      username: null,
    });

    var token =
      localStorage.getItem('JWTtoken') ??
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NjgxNjI2NDYsIm5iZiI6MTY2ODE2MjY0NywiZXhwIjoxNjY4MTkyNjQ2LCJ1c2VyRGF0YSI6eyJ1c2VyX2lkIjoiMSIsInVzZXJuYW1lIjoidGVzdDEiLCJlbWFpbCI6InRlc3QxQGVtYWlsLmNvbSIsImFjdGl2ZSI6IjEiLCJjYW5fbWFuYWdlX3VzZXJzIjoiMSIsInRheG9ub215X3Rvb2xfYWNjZXNzIjoiMCIsInBvcnRhbF9lZGl0b3JfdG9vbF9hY2Nlc3MiOiIwIn19.JY8b398QKGpBA231y0dZlFROzaXSkFBD06pYBKyDFsY';

    var config = {
      method: 'post',
      url: 'https://api.211support.org/api/delete-user/' + originalRow.user_id,
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setSnackbarMessage(response.data);
        setSnackbarOpen(true);
        props.handleUserChange();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      props.UsersObject.data[row.index] = values;
      console.log(props.UsersObject.data[row.index].user_id);
      var updateData = props.UsersObject.data[row.index];

      var FormData = require('form-data');
      var data = new FormData();

      for (var key in updateData) {
        data.append(key, updateData[key]);
      }

      var token =
        localStorage.getItem('JWTtoken') ??
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NjgxNjI2NDYsIm5iZiI6MTY2ODE2MjY0NywiZXhwIjoxNjY4MTkyNjQ2LCJ1c2VyRGF0YSI6eyJ1c2VyX2lkIjoiMSIsInVzZXJuYW1lIjoidGVzdDEiLCJlbWFpbCI6InRlc3QxQGVtYWlsLmNvbSIsImFjdGl2ZSI6IjEiLCJjYW5fbWFuYWdlX3VzZXJzIjoiMSIsInRheG9ub215X3Rvb2xfYWNjZXNzIjoiMCIsInBvcnRhbF9lZGl0b3JfdG9vbF9hY2Nlc3MiOiIwIn19.JY8b398QKGpBA231y0dZlFROzaXSkFBD06pYBKyDFsY';

      var config = {
        method: 'post',
        url:
          'https://api.211support.org/api/update-user/' + props.UsersObject.data[row.index].user_id,
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);
          setSnackbarMessage(response.data);
          setSnackbarOpen(true);
          props.handleUserChange();
        })
        .catch(function (error) {
          console.log(error);
        });

      //send/receive api updates here, then refetch or update local table data for re-render
      //   setTableData([...tableData]);
      exitEditingMode(); //required to exit editing mode and close modal
    }
  };

  //   for Exporting data
  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columnsMemo.map((c) => c.header),
  };

  // const csvExporter = new ExportToCsv(csvOptions);

  // const handleExportData = () => {
  //   csvExporter.generateCsv(props.topicsObject ? props.topicsObject.data : []);
  // };

  return (
    <>
      <MaterialReactTable
        columns={columnsMemo}
        data={props.UsersObject ? props.UsersObject.data : []}
        initialState={{
          density: 'compact',
          pagination: { pageSize: 10, pageIndex: 0 },
        }}
        enableColumnOrdering={false}
        enableDensityToggle={false} //density does not work with memoized cells
        memoMode="cells"
        enableColumnDragging={false}
        state={{ showSkeletons: props.isLoading }}
        enableRowVirtualization
        enableRowSelection
        enableStickyHeader
        muiTablePaginationProps={{ rowsPerPageOptions: [5, 10] }}
        enableEditing
        enablePinning
        onEditingRowSave={handleSaveRowEdits}
        enableBottomToolbar
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'block', gap: '1rem' }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => table.setEditingRow(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => deleteRowDialogHandle(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={({ table }) => (
          <Typography variant="h6" color="primary">
            Manage Users
          </Typography>
        )}
        // renderBottomToolbarCustomActions={({ table }) => (
        //   <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
        //     <Button
        //       color="primary"
        //       //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
        //       onClick={handleExportData}
        //       startIcon={<FileDownloadIcon />}
        //       variant="contained"
        //       size="small"
        //     >
        //       Export All Data
        //     </Button>
        //   </Box>
        // )}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
          },
        }}
        renderDetailPanel={({ row }) => (
          <Box
            sx={{
              display: 'grid',
              margin: 'auto',
              width: '100%',
            }}
          >
            {Object.keys(row.original).map(function (key) {
              return (
                <Typography key={key}>
                  {key} : {row.original[key]} <br />
                </Typography>
              );
            })}
          </Box>
        )}
      />
      <DeleteDialog
        deleteDialogOpen={deleteDialogOpen}
        setDeleteDialogOpen={setDeleteDialogOpen}
        portalNumber={props.portalNumber}
        originalRow={originalRow}
        deleteRowHandle={deleteRowHandle}
      />
      <MuiSnackBar
        snackbarMessage={snackbarMessage}
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
      />
    </>
  );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
const validateAge = (age) => age >= 18 && age <= 50;

export default UsersTable;
