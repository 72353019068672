import { Routes, Route } from 'react-router-dom';
import Navbar from '../components/Navbar/Navbar';
import ContactUsPage from '../pages/ContactUs/ContactUsPage';
import ErrorPage from '../pages/ErrorPage/ErrorPage';
import LoginPage from '../pages/Login/LoginPage';
import RegisterPage from '../pages/Register/RegisterPage';
import LandingPage from '../pages/Landing/LandingPage';
import TopicsEditorPage from '../pages/TopicsEditor/TopicsEditorPage';
import Footer from '../components/Footer/Footer';
import TestTable from '../pages/Tests/TestTable';
import PrivateRoute from '../components/PrivateRoute/PrivateRoute';
import LogoutPage from '../pages/Logout/LogoutPage';
import UserManagementPage from '../pages/UserManagement/UserManagementPage';
import ProfilePage from '../pages/Profile/ProfilePage';

const Router = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/test" element={<TestTable />} />
        <Route
          path="/topics-editor"
          element={
            <PrivateRoute>
              <TopicsEditorPage />
            </PrivateRoute>
          }
        />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route
          path="/login"
          element={
            <PrivateRoute>
              <LoginPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/register"
          element={
            <PrivateRoute>
              <RegisterPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/user-management"
          element={
            <PrivateRoute>
              <UserManagementPage />
            </PrivateRoute>
          }
        />
        <Route path="/logout" element={<LogoutPage />} />
        {/* <Route path="/login" element={<Login />} />
                                                            <Route path="/resetpassword" element={<ResetPassword />} /> */}
        {/* <Route path="/profile" element={<Profile />} /> */}
        {/* <Route
                                                                path="/profile"
                                                                element={
                                                                  <PrivateRoute>
                                                                  <Profile />
                                                                  </PrivateRoute>
                                                                }
                                                              /> */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </>
  );
};

export default Router;
