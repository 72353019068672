import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';

import { UserContext } from '../../hooks/userContext/userContext';

//banner image
import landingBanner from '../../assets/landingBanner.jpg';

const RegisterPage = () => {
  const { registerUser } = useContext(UserContext);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const onSubmit = (registerData) => {
    // console.log(registerData);
    registerUser(registerData);
  };
  // console.log(errors);

  return (
    <>
      <Box
        variant="div"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ minheight: '100vh', width: '100vw', pt: 2 }}
      >
        <Box sx={{ width: { xs: '100%', sm: '450px', md: '450px' } }}>
          <Card>
            <CardMedia component="img" height="140" image={landingBanner} alt="unsplash Image" />
            <form onSubmit={handleSubmit(onSubmit)}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" align="center">
                  Register
                </Typography>
                <Stack py={2} spacing={2}>
                  <TextField
                    id="outlined"
                    label="Username"
                    variant="outlined"
                    type="text"
                    fullWidth
                    autoFocus={true}
                    name="username"
                    {...register('username', {
                      required: 'Username is required.',
                      minLength: {
                        value: 2,
                        message: 'Please enter a longer name then 2 Characters',
                      },
                    })}
                    error={Boolean(errors.username)}
                    helperText={errors.username?.message}
                  />
                  <TextField
                    id="outlined-basic"
                    label="E-mail"
                    variant="outlined"
                    type="email"
                    fullWidth
                    name="email"
                    {...register('email', {
                      required: 'E-mail Address is required.',
                    })}
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Password"
                    variant="outlined"
                    type="password"
                    fullWidth
                    name="password"
                    {...register('password', {
                      required: 'Password Address is required.',
                      minLength: { value: 5, message: 'Please enter a longer password then 5' },
                    })}
                    error={Boolean(errors.password)}
                    helperText={errors.password?.message}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Confirm Password"
                    variant="outlined"
                    type="password"
                    fullWidth
                    name="cpassword"
                    {...register('cpassword', {
                      required: 'Password Address is required.',
                      minLength: { value: 5, message: 'Please enter a longer password then 5' },
                      validate: (value) => {
                        const { password } = getValues();
                        // console.log(password);
                        return password === value || 'Passwords does not match';
                      },
                    })}
                    error={Boolean(errors.cpassword)}
                    helperText={errors.cpassword?.message}
                  />
                </Stack>
              </CardContent>
              <Stack px={4} spacing={1}>
                <Button size="medium" type="submit" variant="contained" fullWidth>
                  Register
                </Button>
                <Button size="medium" href={'/login'}>
                  Login
                </Button>
              </Stack>
            </form>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default RegisterPage;
