import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { Box, Stack, Typography } from '@mui/material';
import UsersTable from './UsersTable/UsersTable';

const UserManagementPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [usersObject, setUsersObject] = useState();
  const [userChange, setuserChange] = useState(false);

  const handleUserChange = (event) => {
    setuserChange(!userChange);
  };

  useEffect(() => {
    setIsLoading(true);

    var token =
      localStorage.getItem('JWTtoken') ??
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NjgxNjI2NDYsIm5iZiI6MTY2ODE2MjY0NywiZXhwIjoxNjY4MTkyNjQ2LCJ1c2VyRGF0YSI6eyJ1c2VyX2lkIjoiMSIsInVzZXJuYW1lIjoidGVzdDEiLCJlbWFpbCI6InRlc3QxQGVtYWlsLmNvbSIsImFjdGl2ZSI6IjEiLCJjYW5fbWFuYWdlX3VzZXJzIjoiMSIsInRheG9ub215X3Rvb2xfYWNjZXNzIjoiMCIsInBvcnRhbF9lZGl0b3JfdG9vbF9hY2Nlc3MiOiIwIn19.JY8b398QKGpBA231y0dZlFROzaXSkFBD06pYBKyDFsY';

    var config = {
      method: 'get',
      url: 'https://api.211support.org/api/all-users',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data);
        Object.keys(response.data.data).forEach((k) =>
          Object.keys(response.data.data[k]).forEach(
            (j) =>
              (response.data.data[k][j] =
                response.data.data[k][j] === null ? '' : response.data.data[k][j]),
          ),
        );
        setUsersObject(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(() => {
        // Always executed
        setIsLoading(false);
      });
  }, [userChange]);

  //   console.log(usersObject);

  return (
    <Stack spacing={2} p={2} textAlign="center">
      <Box variant="div" sx={{ maxWidth: '100%' }}>
        <UsersTable
          UsersObject={usersObject}
          isLoading={isLoading}
          handleUserChange={handleUserChange}
        />
      </Box>
    </Stack>
  );
};

export default UserManagementPage;
