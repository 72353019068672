import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

import logo211 from '../../assets/logo211.png';
import findhelp_logo from '../../assets/findhelp_logo.png';

const Footer = () => {
  return (
    <Box
      variant="div"
      sx={{ width: '100%', pt: 5 }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Stack variant="div" sx={{ width: { xs: '50%', md: '20%' } }}>
        {/* <Stack direction="row" spacing={2} pt={1}>
          <img height="auto" width="40%" src={logo211} alt="211 Logo" />
          <img height="auto" width="40%" src={findhelp_logo} alt="211 Logo" />
        </Stack> */}
        <Typography variant="subtitle1" sx={{ pt: 1 }} align="center">
          © 2022, Developed by{' '}
          <a href="https://www.findhelp.ca/" target="_blank" rel="noreferrer">
            Findhelp
          </a>
        </Typography>
      </Stack>
    </Box>
  );
};

export default Footer;
