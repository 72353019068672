import { createTheme, colors } from '@mui/material';

export const theme = createTheme({
  status: { danger: '#e53e3e' },
  palette: {
    secondary: {
      main: colors.orange[500],
    },
    neutral: {
      main: colors.grey[500],
      darker: colors.grey[700],
    },
  },
});
