import { TabContext, TabList } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import React from 'react';

const TopicsTabs = (props) => {
  return (
    <TabContext value={props.portalNumber[0]}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList
          onChange={props.handlePortalChange}
          aria-label="lab API tabs example"
          variant="scrollable"
        >
          <Tab label="211 Central" value="1" />
          <Tab label="211 Manitoba" value="4" />
          <Tab label="211 New Brunswick" value="5" />
          <Tab label="211 NewFoundland" value="6" />
          <Tab label="211 Yukon" value="7" />
          <Tab label="Eapon" value="2" />
          <Tab label="Ethp" value="3" />
          <Tab label="OVSS" value="11" />
          <Tab label="Good2Talk" value="10" />
          <Tab label="East York Region North Durham" value="12" />
          <Tab label="Western York Region" value="9" disabled />
          <Tab label="Family Compass Waterloo Region" value="8" disabled />
        </TabList>
      </Box>
    </TabContext>
  );
};

export default React.memo(TopicsTabs);
