import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';

import { UserContext } from '../../hooks/userContext/userContext';

//banner image
import landingBanner from '../../assets/landingBanner.jpg';

const LoginPage = () => {
  const { login } = useContext(UserContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (loginData) => {
    // console.log(loginData);
    login(loginData);
  };
  // console.log(errors);

  return (
    <>
      <Box
        variant="div"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ minheight: '100vh', width: '100vw', pt: 2 }}
      >
        <Box sx={{ width: { xs: '100%', sm: '450px', md: '450px' } }}>
          <Card>
            <CardMedia component="img" height="140" image={landingBanner} alt="unsplash Image" />
            <form onSubmit={handleSubmit(onSubmit)}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" align="center">
                  Login
                </Typography>
                <Stack py={2} spacing={2}>
                  <TextField
                    id="outlined"
                    label="E-mail"
                    variant="outlined"
                    type="email"
                    fullWidth
                    autoFocus={true}
                    name="email"
                    {...register('email', {
                      required: 'E-mail Address is required.',
                    })}
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Password"
                    variant="outlined"
                    type="password"
                    fullWidth
                    name="password"
                    {...register('password', {
                      required: 'Password Address is required.',
                      minLength: { value: 5, message: 'Please enter a longer password then 5' },
                    })}
                    error={Boolean(errors.password)}
                    helperText={errors.password?.message}
                  />
                </Stack>
              </CardContent>
              <Stack px={4} spacing={1}>
                <Button size="medium" type="submit" variant="contained" fullWidth>
                  login
                </Button>
                <Button size="medium" href={'/register'}>
                  Register
                </Button>
                <Typography align="center">
                  Or
                  <Button size="small" href={'/forget-password'}>
                    forgot password?
                  </Button>
                </Typography>
              </Stack>
            </form>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default LoginPage;
