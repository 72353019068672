import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { Box, Stack } from '@mui/material';

import TopicsTabs from './TopicsTabs';
import TopicsTable from './TopicsTable/TopicsTable';

const TopicsEditorPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [portalNumber, setPortalNumber] = useState(['1', '211 Central']);
  const [topicsObject, setTopicsObject] = useState();

  const handlePortalChange = (event, newValue = portalNumber[0]) => {
    switch (newValue) {
      case '1':
        setPortalNumber(['1', '211 Central']);
        break;
      case '2':
        setPortalNumber(['2', '211 Eapon - Elder Abuse Prevention Ontario']);
        break;
      case '3':
        setPortalNumber(['3', '211 Ethp - Eastern Toronto Health Partners']);
        break;
      case '4':
        setPortalNumber(['4', '211 Manitoba']);
        break;
      case '5':
        setPortalNumber(['5', '211 New Brunswick']);
        break;
      case '6':
        setPortalNumber(['6', '211 New Foundland']);
        break;
      case '7':
        setPortalNumber(['7', '211 Yukon']);
        break;
      case '8':
        setPortalNumber(['8', 'Family Compass Waterloo Region']);
        break;
      case '9':
        setPortalNumber(['9', 'Western York Region']);
        break;
      case '10':
        setPortalNumber(['10', 'Good2Talk']);
        break;
      case '11':
        setPortalNumber(['11', 'OVSS']);
        break;
      case '12':
        setPortalNumber(['11', 'East York Region North Durham']);
        break;

      default:
        setPortalNumber(['1', '211 Central']);
        break;
    }
  };

  useEffect(() => {
    setIsLoading(true);

    var config = {
      method: 'get',
      url: 'https://portalapi.211central.ca/api/show_all/' + portalNumber[0],
      headers: {
        'Content-Type': 'application/json',
      },
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data);
        Object.keys(response.data.data).forEach((k) =>
          Object.keys(response.data.data[k]).forEach(
            (j) =>
              (response.data.data[k][j] =
                response.data.data[k][j] === null ? '' : response.data.data[k][j]),
          ),
        );
        setTopicsObject(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(() => {
        // Always executed
        setIsLoading(false);
      });
  }, [portalNumber]);

  return (
    <Stack spacing={2} p={2}>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TopicsTabs portalNumber={portalNumber} handlePortalChange={handlePortalChange} />
      </Box>

      <Box variant="div" sx={{ maxWidth: '100%' }}>
        <TopicsTable
          topicsObject={topicsObject}
          handlePortalChange={handlePortalChange}
          isLoading={isLoading}
          portalNumber={portalNumber}
        />
      </Box>
    </Stack>
  );
};

export default TopicsEditorPage;
