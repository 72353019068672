import React, { useCallback, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';

import { Delete, Edit } from '@mui/icons-material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';

import axios from 'axios';
import FormData from 'form-data';
import MuiSnackBar from '../../../components/MaterialComponents/MuiSnackBar';
import DeleteDialog from './DeleteDialog';
import { ExportToCsv } from 'export-to-csv';

const TopicsTable = (props) => {
  const [originalRow, setOriginalRow] = useState({
    id: null,
    topic_name: null,
  });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  //snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState({
    status: null,
    message: null,
    error: false,
  });

  //for Editing
  const getEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid =
            cell.column.id === 'email'
              ? validateEmail(event.target.value)
              : cell.column.id === 'age'
              ? validateAge(+event.target.value)
              : validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors],
  );

  //table columns
  // const columnsMemo = useMemo(() => columns, [getEditTextFieldProps]);
  const columnsMemo = useMemo(
    () => [
      { header: 'id', size: 70, accessorKey: 'id', enableEditing: false, type: 'number' },
      {
        header: 'dpid',
        size: 100,
        accessorKey: 'dpid',
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getEditTextFieldProps(cell),
          type: 'number',
        }),
      },
      {
        header: 'parent_id',
        size: 120,
        accessorKey: 'parent_id',
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getEditTextFieldProps(cell),
          type: 'number',
        }),
      },
      {
        header: 'sortorder',
        size: 120,
        accessorKey: 'sortorder',
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getEditTextFieldProps(cell),
          type: 'number',
        }),
      },
      {
        header: 'lang',
        size: 100,
        accessorKey: 'lang',
      },
      {
        header: 'topic_name',
        accessorKey: 'topic_name',
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getEditTextFieldProps(cell),
          type: 'string',
        }),
      },
      {
        header: 'topic_desc',
        accessorKey: 'topic_desc',
      },
      {
        header: 'topic_keywords',
        accessorKey: 'topic_keywords',
      },
      {
        header: 'topic_records',
        accessorKey: 'topic_records',
      },
      {
        header: 'topic_records_sticky',
        accessorKey: 'topic_records_sticky',
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getEditTextFieldProps(cell),
          type: 'number',
        }),
      },
      {
        header: 'topic_status',
        accessorKey: 'topic_status',
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getEditTextFieldProps(cell),
          type: 'string',
        }),
      },
      {
        header: 'qa_match_mode',
        accessorKey: 'qa_match_mode',
      },
      {
        header: 'qa_match_terms',
        accessorKey: 'qa_match_terms',
      },
      {
        header: 'qa_match_multi',
        accessorKey: 'qa_match_multi',
      },
      {
        header: 'qa_match_mode2',
        accessorKey: 'qa_match_mode2',
      },
      {
        header: 'qa_match_terms2',
        accessorKey: 'qa_match_terms2',
      },
      {
        header: 'image_xs',
        accessorKey: 'image_xs',
      },
      {
        header: 'image_s',
        accessorKey: 'image_s',
      },
      {
        header: 'image_m',
        accessorKey: 'image_m',
      },
      {
        header: 'image_l',
        accessorKey: 'image_l',
      },
      {
        header: 'image_fa',
        accessorKey: 'image_fa',
      },
    ],
    [getEditTextFieldProps],
  );

  //for Delete row
  const deleteRowDialogHandle = (row) => {
    setOriginalRow(row.original);
    setDeleteDialogOpen(true);
  };
  const deleteRowHandle = () => {
    setDeleteDialogOpen(false);
    console.log(originalRow);
    setOriginalRow({
      id: null,
      topic_name: null,
    });

    var data = new FormData();
    data.append('id', originalRow.id);

    var config = {
      method: 'post',
      url: 'https://portalapi.211central.ca/api/delete/' + props.portalNumber[0],
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setSnackbarMessage(response.data);
        setSnackbarOpen(true);
        props.handlePortalChange();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      props.topicsObject.data[row.index] = values;
      console.log(props.topicsObject.data[row.index]);
      var updateData = props.topicsObject.data[row.index];

      var FormData = require('form-data');
      var data = new FormData();

      for (var key in updateData) {
        data.append(key, updateData[key]);
      }

      var config = {
        method: 'post',
        url: 'https://portalapi.211central.ca/api/update/' + props.portalNumber[0],
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);
          setSnackbarMessage(response.data);
          setSnackbarOpen(true);
          props.handlePortalChange();
        })
        .catch(function (error) {
          console.log(error);
        });

      //send/receive api updates here, then refetch or update local table data for re-render
      //   setTableData([...tableData]);
      exitEditingMode(); //required to exit editing mode and close modal
    }
  };

  //for Exporting data
  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columnsMemo.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(props.topicsObject ? props.topicsObject.data : []);
  };

  return (
    <>
      <MaterialReactTable
        columns={columnsMemo}
        data={props.topicsObject ? props.topicsObject.data : []}
        initialState={{ density: 'compact', pagination: { pageSize: 10, pageIndex: 0 } }}
        enableColumnOrdering={false}
        enableDensityToggle={false} //density does not work with memoized cells
        memoMode="cells"
        enableGrouping
        enableColumnDragging={false}
        state={{ showSkeletons: props.isLoading }}
        enableRowVirtualization
        enableRowSelection
        enableStickyHeader
        muiTablePaginationProps={{ rowsPerPageOptions: [5, 10] }}
        enableEditing
        enablePinning
        onEditingRowSave={handleSaveRowEdits}
        enableBottomToolbar
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'block', gap: '1rem' }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => table.setEditingRow(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => deleteRowDialogHandle(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={({ table }) => (
          <Typography variant="h6" color="primary">
            {props.portalNumber[1]}
          </Typography>
        )}
        renderBottomToolbarCustomActions={({ table }) => (
          <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
            <Button
              color="primary"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
              variant="contained"
              size="small"
            >
              Export All Data
            </Button>
            <Button
              disabled={table.getPrePaginationRowModel().rows.length === 0}
              //export all rows, including from the next page, (still respects filtering and sorting)
              onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
              startIcon={<FileDownloadIcon />}
              variant="contained"
              size="small"
            >
              Export Filtered Rows
            </Button>
            <Button
              disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
              //only export selected rows
              onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
              startIcon={<FileDownloadIcon />}
              variant="contained"
              size="small"
            >
              Export Selected Rows
            </Button>
          </Box>
        )}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
          },
        }}
        renderDetailPanel={({ row }) => (
          <Box
            sx={{
              display: 'grid',
              margin: 'auto',
              width: '100%',
            }}
          >
            {Object.keys(row.original).map(function (key) {
              return (
                <Typography key={key}>
                  {key} : {row.original[key]} <br />
                </Typography>
              );
            })}
          </Box>
        )}
      />
      <DeleteDialog
        deleteDialogOpen={deleteDialogOpen}
        setDeleteDialogOpen={setDeleteDialogOpen}
        portalNumber={props.portalNumber}
        originalRow={originalRow}
        deleteRowHandle={deleteRowHandle}
      />
      <MuiSnackBar
        snackbarMessage={snackbarMessage}
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
      />
    </>
  );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
const validateAge = (age) => age >= 18 && age <= 50;

export default TopicsTable;
