import { Box, Button, Link, Stack, Typography } from '@mui/material';
import React from 'react';

//banner image
import landingBanner from '../../assets/landingBanner.jpg';

const ContactUsPage = () => {
  return (
    <Stack
      spacing={{ xs: 2, sm: 3 }}
      sx={{ px: { xs: 0, md: 12, lg: 12, xl: 20 } }}
      justifyContent="center"
      alignItems="center"
    >
      <Box
        variant="div"
        sx={{ width: '100%', maxHeight: { xs: 200, md: 400 }, overflow: 'hidden' }}
      >
        <img
          src={landingBanner}
          alt="Topics Editor Loading Banner"
          loading="lazy"
          width="100%"
          height="100%"
        />
      </Box>
      <Typography gutterBottom variant="h4" component="div">
        Portal Topics Editor
      </Typography>
      <Typography gutterBottom variant="body1" color="text.secondary">
        The Birdeye view of all 211 Portals
      </Typography>
      <Typography gutterBottom variant="body1" color="text.secondary">
        Please visit <Link href={'/'}>Homepage</Link> to Register/Login. Or to get started and give
        feedback please contact Findhelp Information Services
      </Typography>
      <Box variant="div" sx={{ width: '25%', pt: 1 }}>
        <Button
          size="large"
          target="_blank"
          rel="noopener noreferrer"
          href={`mailto:itteam+portaleditortool@findhelp.ca`}
          variant="contained"
          fullWidth
        >
          Mail Us!
        </Button>
      </Box>
    </Stack>
  );
};

export default ContactUsPage;
