import { Box, Button, ButtonGroup, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';

import landingBanner from '../../assets/landingBanner.jpg';
import { UserContext } from '../../hooks/userContext/userContext';

const LogoutPage = () => {
  const { user } = useContext(UserContext);

  if (user.auth) {
    localStorage.setItem(
      'JWTtoken',
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NjgxNjI2NDYsIm5iZiI6MTY2ODE2MjY0NywiZXhwIjoxNjY4MTkyNjQ2LCJ1c2VyRGF0YSI6eyJ1c2VyX2lkIjoiMSIsInVzZXJuYW1lIjoidGVzdDEiLCJlbWFpbCI6InRlc3QxQGVtYWlsLmNvbSIsImFjdGl2ZSI6IjEiLCJjYW5fbWFuYWdlX3VzZXJzIjoiMSIsInRheG9ub215X3Rvb2xfYWNjZXNzIjoiMCIsInBvcnRhbF9lZGl0b3JfdG9vbF9hY2Nlc3MiOiIwIn19.JY8b398QKGpBA231y0dZlFROzaXSkFBD06pYBKyDFsY',
    );
  }

  return (
    <Stack
      spacing={{ xs: 2, sm: 3 }}
      sx={{ px: { xs: 0, md: 12, lg: 12, xl: 20 } }}
      justifyContent="center"
      alignItems="center"
    >
      <Box
        variant="div"
        sx={{ width: '100%', maxHeight: { xs: 200, md: 400 }, overflow: 'hidden' }}
      >
        <img
          src={landingBanner}
          alt="Topics Editor Loading Banner"
          loading="lazy"
          width="100%"
          height="100%"
        />
      </Box>
      <Typography variant="h4">Logged Out!</Typography>
      <ButtonGroup
        variant="text"
        disableElevation
        size="large"
        color="primary"
        aria-label="Login/Register button group"
      >
        <Button href={'/register'}>Register</Button>
        <Button href={'/login'}>Login</Button>
      </ButtonGroup>
    </Stack>
  );
};

export default LogoutPage;
