import { useEffect, useState } from 'react';
import MuiSnackBar from '../../components/MaterialComponents/MuiSnackBar';
import { UserContext } from './userContext';

import axios from 'axios';
import { Box, CircularProgress } from '@mui/material';
import { Navigate } from 'react-router-dom';

const UserContextProvider = (props) => {
  //portal loading
  const [isLoading, setLoading] = useState(true);

  // User is the name of the "data" that gets stored in context
  const [user, setUser] = useState({ data: null, auth: false });

  //snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState({
    status: null,
    message: null,
    error: false,
  });

  //On Page Load
  useEffect(() => {
    const isAuthenticated = () => {
      // console.log(localStorage.getItem('JWTtoken'));

      var token =
        localStorage.getItem('JWTtoken') ??
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NjgxNjI2NDYsIm5iZiI6MTY2ODE2MjY0NywiZXhwIjoxNjY4MTkyNjQ2LCJ1c2VyRGF0YSI6eyJ1c2VyX2lkIjoiMSIsInVzZXJuYW1lIjoidGVzdDEiLCJlbWFpbCI6InRlc3QxQGVtYWlsLmNvbSIsImFjdGl2ZSI6IjEiLCJjYW5fbWFuYWdlX3VzZXJzIjoiMSIsInRheG9ub215X3Rvb2xfYWNjZXNzIjoiMCIsInBvcnRhbF9lZGl0b3JfdG9vbF9hY2Nlc3MiOiIwIn19.JY8b398QKGpBA231y0dZlFROzaXSkFBD06pYBKyDFsY';

      var FormData = require('form-data');
      var data = new FormData();
      var config = {
        method: 'post',
        url: 'https://api.211support.org/api/is-authenticated',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        data: data,
      };

      // console.log(config);

      axios(config)
        .then(function (response) {
          // console.log(response.data);
          if (!response.data.error) {
            localStorage.setItem('JWTtoken', token);
            setUser((user) => ({
              data: response.data.data.userData,
              auth: response.data.data.userData.portal_editor_tool_access === '1',
            }));
          } else {
            setSnackbarMessage(response.data);
            setSnackbarOpen(true);
            setUser((user) => ({
              data: '',
              auth: false,
            }));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    isAuthenticated();
    setLoading(false);
  }, [isLoading]);

  // Login updates the user data with a name parameter
  const login = (loginData) => {
    var FormData = require('form-data');
    var data = new FormData();

    for (var key in loginData) {
      data.append(key, loginData[key]);
    }

    data.append('forWebsite', 'portal_editor_tool_access');

    var config = {
      method: 'post',
      url: 'https://api.211support.org/api/login',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data);
        setSnackbarMessage(response.data);
        setSnackbarOpen(true);
        if (!response.data.error) {
          localStorage.setItem('JWTtoken', response.data.data.token);
          setUser((user) => ({
            data: response.data.data.userData,
            auth: response.data.data.userData.portal_editor_tool_access === '1',
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //register user
  const registerUser = (registerData) => {
    var FormData = require('form-data');
    var data = new FormData();

    for (var key in registerData) {
      data.append(key, registerData[key]);
    }

    var config = {
      method: 'post',
      url: 'https://api.211support.org/api/register',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data);
        setSnackbarMessage(response.data);
        setSnackbarOpen(true);
        if (!response.data.error) {
          return response.data;
        }
      })
      .catch(function (error) {
        console.log(error);
        return null;
      });
  };

  // Logout updates the user data to default
  const logout = () => {
    if (user.auth) {
      localStorage.setItem(
        'JWTtoken',
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NjgxNjI2NDYsIm5iZiI6MTY2ODE2MjY0NywiZXhwIjoxNjY4MTkyNjQ2LCJ1c2VyRGF0YSI6eyJ1c2VyX2lkIjoiMSIsInVzZXJuYW1lIjoidGVzdDEiLCJlbWFpbCI6InRlc3QxQGVtYWlsLmNvbSIsImFjdGl2ZSI6IjEiLCJjYW5fbWFuYWdlX3VzZXJzIjoiMSIsInRheG9ub215X3Rvb2xfYWNjZXNzIjoiMCIsInBvcnRhbF9lZGl0b3JfdG9vbF9hY2Nlc3MiOiIwIn19.JY8b398QKGpBA231y0dZlFROzaXSkFBD06pYBKyDFsY',
      );
      setUser((user) => ({
        data: {},
        auth: false,
      }));
    }
    Navigate('/');
  };

  if (isLoading === true || user.data === null) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <>
        <UserContext.Provider value={{ user, login, logout, registerUser }}>
          {props.children}
        </UserContext.Provider>
        <MuiSnackBar
          snackbarMessage={snackbarMessage}
          snackbarOpen={snackbarOpen}
          setSnackbarOpen={setSnackbarOpen}
        />
      </>
    );
  }
};

export default UserContextProvider;
