import React, { useContext } from 'react';

import { UserContext } from '../../hooks/userContext/userContext';

//banner image
import landingBanner from '../../assets/landingBanner.jpg';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  List,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

const ProfilePage = () => {
  const { user } = useContext(UserContext);
  // console.log(user);

  return (
    <>
      <Box
        variant="div"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ minheight: '100vh', width: '100vw', pt: 2 }}
      >
        <Box sx={{ width: { xs: '100%', sm: '450px', md: '450px' } }}>
          <Card>
            <CardMedia component="img" height="140" image={landingBanner} alt="unsplash Image" />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" align="center">
                Profile
              </Typography>
              <Stack py={2} spacing={2}>
                {user.auth ? (
                  <List>
                    {Object.entries(user.data).map(([key, value]) => (
                      <Typography key={key} variant="body1">
                        <strong>{key}:</strong> {value}
                      </Typography>
                    ))}
                  </List>
                ) : (
                  <Typography>Empty</Typography>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default ProfilePage;
