import { Alert, Snackbar } from '@mui/material';
import React, { forwardRef } from 'react';

const SnackbarAlert = forwardRef(function snackbarAlert(props, ref) {
  return <Alert elevation={6} ref={ref} {...props} />;
});

const MuiSnackBar = (props) => {
  return (
    <Snackbar
      autoHideDuration={6000}
      open={props.snackbarOpen}
      onClose={() => props.setSnackbarOpen(false)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <SnackbarAlert
        onClose={() => props.setSnackbarOpen(false)}
        severity={props.snackbarMessage.error === false ? 'success' : 'info'}
        variant="filled"
      >
        {JSON.stringify(props.snackbarMessage.message) === '"Expired token"'
          ? 'Please Login'
          : JSON.stringify(props.snackbarMessage.message)}
      </SnackbarAlert>
    </Snackbar>
  );
};

export default MuiSnackBar;
