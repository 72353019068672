import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';

const DeleteDialog = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      open={props.deleteDialogOpen}
      onClose={() => props.setDeleteDialogOpen(false)}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
      fullScreen={fullScreen}
    >
      <DialogTitle id="dialog-title">Delete User?</DialogTitle>
      <DialogContent>
        <Stack aria-label="dialog-description">
          <Typography varaint="body1" gutterBottom>
            Are you sure you want to delete this User?
          </Typography>
          <Typography varaint="body2">id = {props.originalRow.user_id}</Typography>
          <Typography varaint="body2">User Name = {props.originalRow.username}</Typography>
          <Typography varaint="body2">Email = {props.originalRow.email}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => props.setDeleteDialogOpen(false)}>
          Cancel
        </Button>
        <Button autoFocus variant="contained" color="error" onClick={props.deleteRowHandle}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
