import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { UserContext } from '../../hooks/userContext/userContext';

const PrivateRoute = (props) => {
  const { user } = useContext(UserContext);
  // console.log(user);

  //for current url
  const location = useLocation();
  const currentURL = location.pathname;

  if (user.auth) {
    switch (currentURL) {
      case '/login':
        return <Navigate to="/topics-editor" />;
      case '/register':
        return <Navigate to="/topics-editor" />;
      case '/topics-editor':
        return props.children;
      case '/user-management':
        if (user.data.can_manage_users === '1') {
          return props.children;
        } else {
          return <Navigate to="/" />;
        }
      case '/profile':
        if (user.data.active === '1') {
          return props.children;
        } else {
          return <Navigate to="/login" />;
        }
      default:
        return props.children;
    }
  } else {
    switch (currentURL) {
      case '/login':
        return props.children;
      case '/register':
        return props.children;
      case '/topics-editor':
        return <Navigate to="/" />;
      case '/user-management':
        return <Navigate to="/" />;
      case '/profile':
        return <Navigate to="/" />;
      default:
        return <Navigate to="/" />;
    }
  }
};

export default PrivateRoute;
