import './App.css';

//context
import UserContextProvider from './hooks/userContext/userContextProvider';

//theme
import MuiThemeProvider from './components/MaterialComponents/MuiThemeProvider';

//routes
import Router from './Router/Router';

function App() {
  return (
    <>
      <UserContextProvider>
        <MuiThemeProvider>
          <Router />
        </MuiThemeProvider>
      </UserContextProvider>
    </>
  );
}

export default App;
