import { Box, Button, ButtonGroup, Stack, Typography } from '@mui/material';
import React from 'react';

//banner image
import landingBanner from '../../assets/landingBanner.jpg';
const LandingPage = () => {
  return (
    <Stack
      spacing={{ xs: 2, sm: 3 }}
      sx={{ px: { xs: 0, md: 12, lg: 12, xl: 20 } }}
      justifyContent="center"
      alignItems="center"
    >
      <Box
        variant="div"
        sx={{ width: '100%', maxHeight: { xs: 200, md: 400 }, overflow: 'hidden' }}
      >
        <img
          src={landingBanner}
          alt="Topics Editor Loading Banner"
          loading="lazy"
          width="100%"
          height="100%"
        />
      </Box>
      <Typography variant="h4">Portal Topics Editor</Typography>
      <ButtonGroup
        variant="text"
        disableElevation
        size="large"
        color="primary"
        aria-label="Login/Register button group"
      >
        <Button href={'/register'}>Register</Button>
        <Button href={'/login'}>Login</Button>
      </ButtonGroup>
    </Stack>
  );
};

export default LandingPage;
